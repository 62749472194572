const navigator = () => {
  $(() => {
    $(".navbar-toggler").on("click", function () {
      $(this).toggleClass("collapsed");
      $("#navbarToggler").toggleClass("aperto");
      $("body").toggleClass("noscroll");
    });

    const header = document.querySelector(".header");
    const buttonUp = document.getElementById("button__up");
    window.addEventListener("scroll", function () {
      if (window.scrollY > 20) {
        header.classList.add("scrolled");
        if (buttonUp) {
          buttonUp.classList.add("scrolled");
        }
      } else {
        header.classList.remove("scrolled");
        if (buttonUp) {
          buttonUp.classList.remove("scrolled");
        }
      }
    });

    const dropdowns = document.querySelectorAll("#menu-main-menu .menu-item-has-children");
    if (dropdowns.length > 0) {
      dropdowns.forEach((dropdown) => {
        const toggler = dropdown.querySelector("a");
        const subMenu = dropdown.querySelector(".sub-menu");

        if (toggler && subMenu) {
          toggler.addEventListener("click", () => {
            dropdown.classList.toggle("open");
          });
        }
      });
    }

    const headerHeight = document.querySelector(".header").offsetHeight;
    if (window.location.hash) {
      const targetId = window.location.hash.substring(1);
      console.log(headerHeight);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        setTimeout(() => {
          const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({
            top: targetPosition - headerHeight,
            behavior: "smooth",
          });
        }, 300);
      }
    }
  });
};

export default navigator;
